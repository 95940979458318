/*---------------Default--CSS---------------*/
@import url('https://fonts.googleapis.com/css2?family=Inclusive+Sans&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-image: var(--body_nav_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff !important;
  font-family: 'Inclusive Sans', sans-serif;
  
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}


/*---------------Global--CSS---------------*/

.App{
  background-image: var(--body_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  color : var(--body_color);
  padding: 20px 0 0 0;
  transition: background-image 0.3s ease-in-out;
  
}
/*-----------------Fix_NavBar----------------*/
.Home_nav{
  margin-bottom: 70px;
}
.Home_nav_result{
  margin-bottom: 0px;
}

/*---------------NavBar--CSS---------------*/

.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  padding: 20px 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  transition: background-color 0.3s ease-in-out;
}

.nav.transparent {
  background-color:var(--nav_background);
}


.nav.solid {
  background-color: var(--nav_scroll_background);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  height: 60px;
}

.nav--title {
  font-size: 24px;
  font-weight: bold;
  margin-left: 20px;
}
.nav--logo_text, .nav--title{
  margin : 0;
}

.nav--logo_text{
  margin:0;
  color : var(--nav_logo_text_color);
  font-weight: bold;
  font-size: 22px;
}

.nav--title {
  color: var(--body_color);
  font-weight: 600;
  margin-left: 80px;
}

.nav--icon{
  height: 30px;
  margin-right: 7px;
}

.nav--link {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
}

.nav--link:hover {
  text-decoration: underline;
}

.nav--container {
  display: flex;
  align-items: center;
}

.nav--title {
  margin-right: 0%;
  color: var(--nav_text_color);
}

.nav--button {
  margin-right: 20px;
}

.nav--dark-mode {
  margin-left: auto;
}

.nav--button {
  background-color:var(--button_background);
  border: none;
  color: var(--button_color);
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nav--button:hover {
  background-color: var(--button_hover_background);
  color: var(--button_hover_color);
}

.nav--button:active {
  transform: scale(0.98);
}
/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .nav {
    height: 60px; 
    padding: 10px 20px;
  }

  .nav--title {
    font-size: 12px; 
  }

  .nav--logo_text {
    font-size: 12px;
    margin-right: -44px; 
  }

  .nav--icon {
    height: 20px;
    margin-right: 7px;
  }

  .nav--link {
    font-size: 14px; 
  }

  .nav--button {
    font-size: 10px; 
  }
}

@media only screen and (max-width: 440px) {
  .nav--title {
    display: none;
  }
  .nav--logo_text {
    font-size: 15px;
    margin-right: 44px; 
  }
  .nav--icon {
    height: 25px;
    margin-right: 7px;
  }
}


/*---------------Main-CSS---------------*/
.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--body_background);
  padding: 20px;
}

.main--mtitle{
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: bold;
  color:var(--body_color);
  margin-top: 10px;
  margin-bottom: 20px;
}

.main--subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color:var(--body_color);
  margin-top: 10px;
  margin-bottom: 20px;
}

.main--text {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color:var(--body_color);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 800px;
  line-height: 1.5;
}

.main--title {
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-weight: bold;
  color:var(--body_color);
  margin-top: 20px;
  margin-bottom: 10px;
}

.main--form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color:var(--form_background);
  padding: 20px;
  margin-bottom: 20px;
}

.main--label {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color:var(--form_text_color);
  margin-top: 10px;
  margin-bottom: 10px;
}

.main--select {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: var(--main_select_text_color);
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  padding-left: 10px;
  background-color: var(--main_select_color);
  margin-bottom: 10px;
}

.main--button{
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #ffffff;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: #00a8ff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}

.main--button:hover {
  background-color: #0097e6;
}

.button--link {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #ffffff;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: none;
  outline: none;
  background-color: #00a8ff;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}

.button--link:hover {
  background-color: #0097e6;
}
/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .main--mtitle {
    font-size: 24px;
  }

  .main--subtitle {
    font-size: 18px; 
  }

  .main--text {
    font-size: 16px; 
  }

  .main--title {
    font-size: 18px;
  }

  .main--form {
    width: 100%;
  }

  .main--label {
    font-size: 16px;
  }

  .main--select {
    font-size: 16px;
  }

  .main--button,
  .button--link {
    font-size: 16px;
    height: 40px;
  }
}

/*---------------Footer-CSS---------------*/
.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--footer_background);
  color: var(--footer_text_color);
  padding: 20px;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: var(--footer_icons_bg);
  display: inline-flex;
  border-radius: 30%;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 30%;
  transform: scale(0);
transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
width: 40%;
z-index: 1;
transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}
.footer--title {
  font-size: 24px;
  font-weight: bold;
  color: var(--text-color);
  margin-bottom: 10px;
}

.footer--text {
  font-size: 16px;
  text-align: center;
  color: var(--secondary-color);
  margin-bottom: 10px;
  max-width: 410px;
  margin: 10px auto;
}
/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .footer {
    padding: 10px;
  }
  
  .social-icon {
    margin-left: 10px;
  }

  .social-icon a {
    margin-right: 10px;
  }
  
  .footer--title {
    font-size: 20px;
  }
  
  .footer--text {
    font-size: 14px;
    max-width: 300px;
  }
}


/*---------------Clac--CSS---------------*/
.calc--section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: var(--calc_background);
  padding: 20px;

  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: background-image 0.3s ease-in-out;
}

.calc--title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--calc_text_color);
}

.calc--form {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  border: 1px solid var(--calc_border_color);
  padding: 20px;
  margin: 0 auto;
  max-width: 50%;
  border-radius: 25px;
}

.calc--form label {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: var(--calc_text_color);
  display: block;
}

.calc--form input[type="number"] {
  padding: 10px;
  font-size: 1.2rem;
  border: none;
  border-radius: 25px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
  max-width: 100%;
}

.calc--form input[type="submit"] {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  max-width: 100%;
  transition: background-color 0.3s ease;
}




.calc--form input[type="submit"]:hover {
  background-color: #555;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}


/* Responsive design for calculator */
@media screen and (max-width: 768px) {
  .calc--form {
     flex-direction: column;
     max-width: 100%;
   }
 
  .calc--form input[type="number"] {
     max-width: 100%;
     margin-bottom: 10px;
   }
 
  .calc--form input[type="submit"] {
     width: 100%;
   }
 }


/*---------------Result--CSS---------------*/
/* Result Loader */
.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: var(--result_loader_background);
}

.loading-text {
  font-size: 2rem;
  margin-top: 2rem;
  color: #666666;
}

/* Result Section */
.result--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: var(--result_background);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-family: "Open Sans", sans-serif;
  padding: 20px;
}

.Result--Title {
  font-size: 3rem;
  margin-top: 2rem;
  color: var(--result_text_color);
  text-align: center;
}

.Result--text {
  font-size: 2rem;
  margin-top: 2rem;
  color: var(--result_text_color);
  text-align: center;
}

.Result--mention {
  font-size: 1.5rem;
  margin-top: 2rem;
  color: #00b300;
  text-align: center;
}

.button {
  background-color: var(--button_background_color);
  color: var(--button_text_color);
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 25px;
}

.button:hover {
  background-color: var(--button_hover_background_color);
  color: var(--button_hover_color);
}


/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .loader {
    height: 100vh;
  }

  .loading-text {
    font-size: 1.5rem; 
  }

  .result--container {
    min-height: 100vh;
  }

  .Result--Title {
    font-size: 2rem; 
  }

  .Result--text {
    font-size: 1.5rem; 
  }

  .Result--mention {
    font-size: 1rem; 
  }
  .button {
    font-size: 14px;
    padding: 8px 16px;
  }
}




