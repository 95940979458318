:root{
  --body_background: url("./assets/img/light_mode.jpg");
  --body_nav_background: url("./assets/img/light_mode.jpg");
  --result_background: url("./assets/img/light_mode.jpg");
  --calc_background: url("./assets/img/light_mode.jpg");
  --calc_text_color: #000000;
  --calc_border_color: #000000;
  --result_text_color: #000000;
  --result_loader_background: url("./assets/img/light_mode.jpg");
  --result_loader_color: #000000;
  --body_color: #000000;
  --link_color: navy;
  --nav_background: rgb(39, 38, 38);
  --nav_logo_text_color: #38c9f6;
  --nav_text_color: #3c3a3a;
  --button_background: #33373f;
  --button_color: #ffffff;
  --button_hover_background: #838488;
  --button_hover_color: #000000;
  --form_background: #282c34;
  --form_text_color: #ffffff;
  --footer_background: rgb(26, 24, 24,0.9);
  --footer_text_color: #fff;
  --footer_icons_bg: #282c34;
  --button_background_color: #33373f;
  --button_text_color: #ffffff;
  --button_hover_background_color: #838488;
  --button_hover_color: #fff;
  --main_select_color: #f0f0f0;
  --main_select_text_color: #333333;
  --nav_scroll_background: rgb(176, 173, 178);
  
}

[data-theme="dark"]{
  --body_background: url("./assets/img/dark_mode.jpg");
  --body_nav_background: url("./assets/img/dark_mode.jpg");
  --result_background: url("./assets/img/dark_mode.jpg");
  --calc_background: url("./assets/img/dark_mode.jpg");
  --calc_text_color: #ffffff;
  --calc_border_color: #ffffff;
  --result_text_color: #ffffff;
  --result_loader_background: url("./assets/img/dark_mode.jpg");
  --result_loader_color: #ffffff;
  --body_color: #ffffff;
  --link_color: cyan;
  --nav_background: rgba(255, 255, 255, 0.05);
  --nav_logo_text_color: #6aDAFB;
  --nav_text_color: #ffffff;
  --button_background: #ffffff;
  --button_color: #000000;
  --button_hover_background: #0e0d0d;
  --button_hover_color: #dbd7d7;
  --form_background: #ffffff;
  --form_text_color: #000000;
  --footer_background: rgba(255, 255, 255, 0.05);
  --footer_text_color: #ffffff;
  --footer_icons_bg: rgba(217, 217, 217, 0.1);
  --button_background_color: #ffffff;
  --button_text_color: #000000;
  --button_hover_background_color: #0e0d0d;
  --button_hover_color: #dbd7d7;
  --main_select_color: #282c34;
  --main_select_text_color: #fff;
  --nav_scroll_background: rgb(39, 38, 38,1);
}


*{
  transition: all 0.5s ease_in_out;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
